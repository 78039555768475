import ITransactionParser from "./ITransactionParser.mjs";

class BarclaysPlugin extends ITransactionParser {
    info() {
        return "Barclays"
    }

    description() {
        return "Grab: AccountTransactionHistory"
    }

    isApplicable(jsonData) {
        try {
            // Method B
            const transactions = jsonData.Item?.Transactions
            if (transactions && transactions.length > 0) {
                return true;
            }

            // Method A, InitialCallResponse
            for (const item of jsonData.Item?.InitialCallResponses) {
                if (item.Response.Item.Transactions) {
                    return item.Response.Item.Transactions.length > 0
                }
            }
        } catch (error) {
            console.log("Barclays plugin inapplicable");
        }
        return false;
    }

    parseTransactions(jsonData) {
        var transactions = []

        const itemTransactions = jsonData.Item?.Transactions;
        if (itemTransactions) {
            // proceed
            console.log("Detected Method B transactions")
            transactions = this.processTransactions(itemTransactions)
        } else {
            jsonData.Item.InitialCallResponses.forEach(item => {
                const hasTransactions = item.Response.Item.Transactions
                if (hasTransactions) {
                    console.log("Method A transactions")
                    //console.log(item.Response.Item.Transactions)

                    transactions = this.processTransactions(item.Response.Item.Transactions)
                }
            });
        }

        return transactions;
    }

    processTransactions(itemTransactions) {
        const transactions = []

        itemTransactions.forEach(e => {
            let t = {}
            //console.log(e)

            t.TransactionDate = e.TransactionDate
            t.ReferenceNumber = e.ReferenceNumber
            t.Description = e.Description
            t.Amount = e.Amount.Value
            t.AmountCurrency = e.Amount.Currency.Code
            t.LocalCurrencyAmount = e.LocalCurrencyAmount.Value
            t.LocalCurrencyCurrency = e.LocalCurrencyAmount.Currency.Code
            t.TransactionNature = e.TransactionNature
            t.CountryCode = e.CountryCode
            t.MerchantName = e.MerchantName
            t.MobilePaymentType = e.MobilePaymentType ? e.MobilePaymentType : ""
            t.MerchantDataSearchCriteriaIdentifier = e.MerchantData.SearchCriteriaIdentifier
            t.MerchantDataName = e.MerchantData.Name

            //console.log(Object.values(t).join(';'))
            transactions.push(t)
        });

        return transactions;
    }

    toQif(transactions) {
        const qifHeader = [
            `!Account`,
            `NBarclays VISA`,
            `DMy Barclays transactions`,
            `TCCard`,
            `^`,
            `!Type:CCard`
        ]

        const qifTransactions = transactions.flatMap(t => [

            `D${this.formatDate(t.TransactionDate)}`,
            `T${(t.TransactionNature == "Credit") ? t.LocalCurrencyAmount : -t.LocalCurrencyAmount}`,
            `M${t.MerchantName} ${t.MobilePaymentType}`,
            `P${t.Description}`,
            `^`
        ])

        const qifContent = qifHeader.concat(qifTransactions).join("\n")

        return qifContent;
    }

    formatDate(text) {
        const date = new Date(text)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}

export default BarclaysPlugin;