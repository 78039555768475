import ITransactionParser from './ITransactionParser.mjs'

class ZiniaAmazon extends ITransactionParser {
    info() {
        return "Amazon VISA by Zinia"
    }

    description() {
        return "Grab: [...]/transactions[?lim...]"
    }

    isApplicable(jsonData) {
        try {
            return jsonData.elements.length > 0;
        } catch (error) {
            return false;
        }
    }

    parseTransactions(jsonData) {
        const transactions = []
        for (const e of jsonData.elements) {
            //jsonData.elements.forEach(e => {
            //console.log(e)    

            let t = {}
            t.id = e.id
            t.date = e.date

            t.merchantName = e.merchantDetails.name
            t.merchantDetails = `${e.merchantDetails.name}, ${e.merchantDetails.address.city}, ${e.merchantDetails.address.countryCode}`
            t.amountFirstTotal = e.amounts[0].totalAmount // TODO multi amounts
            t.amountFirstCurrency = e.amounts[0].currency

            // Skip reward redemptions
            if (e.reward?.signedAmount < 0) {
                console.log(`disregarding empty transaction`)
            } else {
                t.cashback = e.reward?.signedAmount ? `${e.reward.amount} points earned` : ""

                console.log(t)
                console.log(Object.values(t).join(';'))
                transactions.push(t)
            }
        };

        return transactions;
    }

    toQif(transactions) {
        const qifHeader = [
            `!Account`,
            `NAmazon VISA`,
            `DMy Amazon VISA by Zinia transactions`,
            `TCCard`,
            `^`,
            `!Type:CCard`
        ]

        const qifTransactions = transactions.flatMap(t => [

            `D${t.date}`,
            `T${t.amountFirstTotal}`,
            `M${t.merchantDetails}`,
            `P${t.merchantName}`,
            `^`
        ])

        const qifContent = qifHeader.concat(qifTransactions).join("\n")

        return qifContent;
    }
}

export default ZiniaAmazon;