export default class ITransactionParser {
    info() {
        throw new Error("Method not implemented");
    }

    description() {
        throw new Error("No description given");
    }

    isApplicable(jsonData) {
        throw new Error("Method not implemented");
    }

    parseTransactions(jsonData) {
        throw new Error("Method not implemented");
    }

    toCsv(transactions) {
        const csvHeader = Object.keys(transactions[0]).join(';') + '\n';
        const csvRows = transactions.map(t => Object.values(t).join(';')).join('\n');
        const csvData = csvHeader + csvRows;
        return csvData;
    }

    toQif(transactions) {
        throw new Error("Method not implemented");
    }
}