import ITransactionParser from "./ITransactionParser.mjs";

class KlarnaBank extends ITransactionParser {
    info() {
        return "Klarna Bank";
    }

    description() {
        return "Grab: /de/api/consumer_banking_bff/v1/graphql/transactions/ (2nd past tax-information)"
    }

    getEdges(jsonData) {
        let account = jsonData.data?.account?.transactionConnection?.edges
        let currentAccount = jsonData.data?.currentAccount?.transactionConnection?.edges

        if(account) {
            return account;
        } else if(currentAccount) {
            return currentAccount;
        } else {
            return null;
        }
    }

    isApplicable(jsonData) {        
        try {
            let nodes = this.getEdges(jsonData)
            //let nodes = jsonData.data?.account?.transactionConnection?.edges
            if (nodes) {
                return nodes.length > 0;
            }
        } catch (error) {
            console.log("Klarna Bank inapplicable")
        }
        return false;
    }

    formatDate(dateText) {
        const date = new Date(dateText);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${month}/${day}/${year}`;
    }

    parseTransactions(jsonData) {
        const transactions = [];
        //let edges = jsonData.data?.account?.transactionConnection?.edges
        let edges = this.getEdges(jsonData);

        for (const e of edges) {
            const t = {};
            const item = e.node
            //console.log(e)


            //t.krn = item.krn;
            t.createdAt = item.createdAt;
            t.categoryKey = item.categoryKey;
            t.amountValue = item.amount.value;
            t.amountCurrency = item.amount.currency;
            t.referenceMessage = item.referenceMessage;
            t.otherPartyName = item.otherParty?.name;

            const otherParty = item.otherParty;
            switch (otherParty.__typename) {
                case 'SepaOtherParty':
                    t.otherParty = `${otherParty.name} ${otherParty.id}`;
                    break;
                case 'CardTopUpOtherParty':
                    t.otherParty = `${otherParty.name} ${otherParty.brand} ${otherParty.cardNumber}`;
                    break;
                case 'PoolAccount':
                    t.otherParty = `Pool: ${otherParty.name}`
                    break;
                case 'KlarnaOtherParty':
                    t.otherParty = `KlarnaOtherParty`
                    break;
                default:
                    console.log(e)
                    throw new Error(`Unsupported: ${otherParty.__typename}`)
            }

            transactions.push(t)
        }

        return transactions
    }

    toQif(transactions) {
        const qifHeader = [
            `!Account`,
            `NKlarna Bank Account`,
            `DMy Klarna balance bank account`,
            `TBank`,
            `^`,
            `!Type:Bank`
        ]

        const qifTransactions = transactions.flatMap(t => {


            return [
                `D${this.formatDate(t.createdAt)}`,
                `T${t.amountValue / 100}`
            ].concat([t.referenceMessage, t.otherParty]
                .filter(e => e && e !== '') // filter empty lines
                .map(l => `M${l}`) // prefix with M
                .join('\n'))
                .concat([
                    `P${t.otherPartyName}`,
                    `^`
                ])
        })

        const qifContent = qifHeader.concat(qifTransactions).join("\n")

        return qifContent
    }




}

export default KlarnaBank;