import ITransactionParser from "./ITransactionParser.mjs";

class Revolut extends ITransactionParser {
    info() {
        return "Revolut"
    }

    description() {
        return "Grab: last?[...]"
    }

    isApplicable(jsonData) {
        if (jsonData && jsonData.length > 0) {
            const invalidentries = jsonData.some((e) => {
                return !e.type || !e.state || !e.category
            });
            return !invalidentries;
        }
        return false;
    }


    parseTransactions(jsonData) {
        return jsonData.map((e) => {
            let t = {}
            t.id = e.id;
            t.type = e.type;
            t.state = e.state;
            t.createdDate = e.createdDate;
            t.currency = e.currency;
            t.amount = e.amount / 100;
            t.fee = e.fee;
            t.description = e.description;
            t.category = e.category;
            t.comment = e.comment ? e.comment : ""
            return t;
        });
    }

    toQif(transactions) {
        const qifHeader = [
            `!Account`,
            `NRevolut`,
            `DMy Revolut transactions`,
            `TBank`,
            `^`,
            `!Type:Bank`
        ]
        const qifTransactions = transactions.flatMap(t => [
            `D${this.formatDate(t.createdDate)}`,
            `T${t.amount}`,
            `M${t.type}`,
            `P${t.description}`,
            `^`
        ])
        const qifContent = qifHeader.concat(qifTransactions).join("\n")
        return qifContent;
    }

    formatDate(unixtimems) {
        const date = new Date(unixtimems)
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}
export default Revolut;